<template>
    <v-row no-gutters>
        <v-col cols="12">
            <!-- hierarchical navigation -->
            <v-row justify="start" class="mt-2 mx-4">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-caption text-start">
                    <router-link :to="{ name: 'user-dashboard' }">Dashboard</router-link> &gt;
                    <router-link :to="{ name: 'user-account-list' }">Accounts</router-link> &gt;
                    <router-link :to="{ name: 'account-dashboard', params: { accountId: this.$route.params.accountId } }">{{ accountName }}</router-link> &gt;
                    <router-link :to="{ name: 'account-search-palette', params: { accountId: this.$route.params.accountId } }">Palettes</router-link>
                </p>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 mt-2" v-if="isViewReady">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">{{ palette.label }}</h1>
                <!-- TODO: reply to -->
                <!-- <p class="text-caption text-center">Delete form</p> -->
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5 px-10" v-if="palette">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <p class="text-overline mb-0 mt-8">Name</p>
                    <p class="mb-0 pb-0">
                        {{ palette.label }}
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Comment</p>
                    <p class="mb-0 pb-0">
                        {{ palette.comment }}
                    </p> -->

                    <p class="text-overline mb-0 mt-8">Palette ID</p>
                    <p class="mb-0 pb-0">
                        {{ palette.id }}
                    </p>

                    <!-- <p class="text-overline mb-0 mt-8">Status</p>
                    <p class="mb-0 pb-0">
                        <span v-if="affectedEmailContactList.length > 0">
                            This palette is subscribed by {{ affectedEmailContactList.length }} email contacts.
                            < ! - - TODO: show the affectedEmailContactList - - >
                        </span>
                        <span v-if="affectedEmailContactList.length === 0">
                        Deleting this palette will not affect any of your email contacts because it is not being used.
                        </span>
                    </p> -->
                    <!-- TODO: link to affected tempaltes -->
                    <!--
                    <p class="mb-0 pb-0" v-if="isPublished">
                        <router-link :to="{ name: 'account-view-form-data', params: { accountId: this.$route.params.accountId, paletteId: this.$route.params.paletteId } }">View data</router-link>
                    </p>
                    -->
                </v-col>
            </v-row>

        <!-- <v-row justify="center" class="py-5 mt-2" v-if="form">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
            <h1 class="text-h6 font-weight-light text-center">{{ palette.label }}</h1>
            <p class="text-caption text-center">
                <span v-if="formTypeText[palette.type]">{{ formTypeText[palette.type] }}</span>
                <span v-if="!formTypeText[palette.type]">{{ palette.type }}</span>
            </p>
            </v-col>
        </v-row> -->

        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <h1 class="text-h6 font-weight-light text-center">How to delete this palette</h1>
                <!-- <p class="mt-8">You can completely delete a form if it doesn't appear in any orders.</p> -->
                <!-- <p>If you only have test orders for this form, delete the test account first and then try to delete the palette.</p> -->
                <!-- <p>If the form appears in any orders, you can mark the form as deleted which means it won't be visible or available for purchase by anyone, won't appear in administrator searches (unless you specifically ask to look for deleted forms), and won't appear anywhere except in order histories.</p> -->
                <!-- <p>Delete button is coming soon!</p> -->
                <!-- <template v-if="isPermitServiceAdmin">
                <p class="text-overline mb-0 mt-10">Service Administration <font-awesome-icon :icon="['fas', 'id-badge']" class="green--text"/></p>
                <p class="mb-0 pb-0">
                    <v-btn color="red" class="white--text" @click="deleteProduct">Delete Product</v-btn>
                </p>
                </template> -->
                <p class="mb-0 pb-0">
                    <v-btn color="red" class="white--text" @click="deletePalette">Delete Palette</v-btn>
                </p>
            </v-col>
        </v-row>

<!--
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p class="text-overline mb-0">Product type</p>
                <p>
                    <span>{{ currentProduct.type }}</span>
                </p>
                <p class="text-overline mb-0">Product name</p>
                <p>
                    <span>{{ comment }}</span>
                </p>
                <p class="text-overline mb-0">Product image</p>
                <p>
                    <Avatar :attr="currentProduct" :size="36"/>
                </p>

                <template v-if="currentProduct.type === ACCOUNT_TYPE_FREE">
                <p class="text-overline mb-0">Related forms <span>({{ combinedProductList.length - 1 }})</span></p>
                <v-list class="ma-0 pa-0" v-if="combinedProductList.length > 1">
                    <template v-for="(form, idx) in combinedProductList">
                    <v-list-item @click="selectProduct(palette.id)" v-bind:key="palette.id" class="ma-0 py-1 px-4" v-show="palette.id !== currentProduct.id">
                        <v-list-item-avatar tile style="margin-right: 8px;">
                            <Avatar :attr="form" :size="32"/>
                        </v-list-item-avatar>
                        <v-list-item-content>
                            <v-list-item-title>{{ palette.label }}</v-list-item-title>
                            <v-list-item-subtitle class="text-overline" style="margin-left: 2px">{{ palette.type }}</v-list-item-subtitle>
                        </v-list-item-content>
                        <v-spacer></v-spacer>
                    </v-list-item>
                    <v-divider v-bind:key="idx"></v-divider>
                    </template>
                </v-list>
                </template>

                <template v-if="currentProduct.type === ACCOUNT_TYPE_ENTERPRISE">
                <p class="text-overline mb-0">Authentication realms <span>({{ realmList.length }})</span></p>
                <v-list class="ma-0 pa-0" v-if="realmList.length > 0">
                    <template v-for="(realm, idx) in realmList">
                    <v-list-item v-bind:key="realm.id" class="ma-0 py-1 px-4">
                        <v-img contain :src="getRealmIcon(realm.icon)" v-if="realm.icon" max-width="34" max-height="34" class="mr-5"/>
                        <v-list-item-content class="text-overline" style="font-size: 14px!important">
                            {{ realm.name }}
                        </v-list-item-content>
                        <v-spacer></v-spacer>
                    </v-list-item>
                    <v-divider v-bind:key="idx"></v-divider>
                    </template>
                </v-list>
                </template>
            </v-col>
        </v-row> -->
        <!-- <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <p>
                    <v-btn class="red--text" @click="dialogDeletePalette = true" outlined :disabled="isDeletePaletteDisabled">
                        Delete form
                    </v-btn>
                </p>
                <p v-if="currentProduct.type === ACCOUNT_TYPE_FREE && combinedProductList.length > 1" class="red--text">
                    The related forms must be deleted, or you must unlink yourself from them, before the this form can be deleted.
                </p>
                <p v-if="currentProduct.type === ACCOUNT_TYPE_ENTERPRISE && realmList.length > 0" class="red--text">
                    The authentication realms must be deleted before the form can be deleted.
                </p>
                <v-dialog v-model="dialogDeletePalette" max-width="600">
                    <v-card elevation="4" class="pa-5" style="border: solid 1px red;">
                        <v-card-text class="text-h6 pa-0 pb-3 red--text">Delete form</v-card-text>
                        <v-row justify="center" class="pt-3">
                            <v-col cols="12">
                                <p class="font-weight-light text-body-1">This action is not reversible.</p>
                                <p class="text-body-1 font-weight-light pb-2 red--text">All form data will be permanently deleted.</p>
                            </v-col>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="4" class="red white--text" @click="deleteProduct" :disabled="isDeletePaletteDisabled">
                                Delete
                            </v-btn>
                            <v-btn text class="grey--text" @click="dialogDeletePalette = false">Cancel</v-btn>
                        </v-row>
                    </v-card>
                </v-dialog>
            </v-col>
        </v-row> -->
        </v-col>
    </v-row>
</template>

<style lang="css" scoped>
/* p.pointer {
     cursor: pointer;
}
span.pointer {
     cursor: pointer;
} */
@media print {
  /* add margin around the main content to separate it from the page header and footer */
  @page {
    margin-top: 2cm;
    margin-bottom: 2cm;
  }
  /* removes padding corresponding to navbar and footer when printing document, adds one line of padding to top of document to separate page title from page content */
  .print {
    padding: 0 !important;
  }
  /* hides elements that should not appear in print */
  .no-print {
      display: none !important;
  }

}
</style>

<script>
import { mapState } from 'vuex';
// import RequireLogin from '@/components/RequireLogin.vue';
// import Snackbar from '@/components/Snackbar.vue';
// import Avatar from '@/components/Avatar.vue';
// import TextLink from '@/components/TextLink.vue';
// import { compact } from '@/sdk/input';
// import { ACCOUNT_TYPE_ENTERPRISE, ACCOUNT_TYPE_PERSONAL, ACCOUNT_TYPE_FREE } from '@/constants';

export default {
    components: {
        // RequireLogin,
        // Snackbar,
        // Avatar,
        // TextLink,
    },
    data: () => ({
        palette: null,
        affectedEmailContactList: [],
        error: false,
        // TODO: this is repeated in ViewProduct and DialogCreateProduct, need to consolidate into a library
        dialogDeletePalette: false,
    }),

    /*
    props: ['isAuthenticated', 'isReady', 'isGravatarEnabled'],
*/
    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isReady,
            user: (state) => state.user,
            // formMap: (state) => state.formMap,
            // currentProductId: (state) => state.formId,
        }),
        // ...mapGetters({
        //     currentProduct: 'form', // currently selected form object
        //     combinedProductList: 'combinedProductList',
        // }),
        // TODO: this computed method appears in many places already, it should be moved to vuex store and obtained via mapGetters, OR it should be made into a component like <PermitServiceAdmin>...</PermitServiceAdmin> that will use this computed method and make the conetnt visible or not visible ,  like RequireLogin or what we do in App.vue , instead of repeating the same logic everywhere;  it's just for UI,  server will enforce the role for security, so all functions can still be available in the parent component
        isPermitServiceAdmin() {
            return Array.isArray(this.user?.permit?.role) && this.user.permit.role.includes('service-admin');
        },
        // compactProductNames() {
        //     return this.combinedProductList.map((item) => compact(item.name));
        // },
        // currentProductImageWithGravatarDisabled() {
        //     return { icon: this.currentProduct.icon, isGravatarEnabled: false };
        // },
        isDeletePaletteDisabled() {
            // return (this.currentProduct.type === ACCOUNT_TYPE_FREE && this.combinedProductList.length > 1) || (this.currentProduct.type === ACCOUNT_TYPE_ENTERPRISE && this.realmList.length > 0);
            // return this.combinedProductList.length > 1;
            return this.affectedEmailContactList.length > 0;
        },
        // compactRealmNames() {
        //     return this.realmList.map((item) => compact(item.name || ''));
        // },
        // compactRealmDomains() {
        //     return this.realmList.map((item) => compact(item.type || ''));
        // },
        // realmList() {
        //     const list = Object.values(this.realmMap);
        //     list.sort((a, b) => (`${a.name}`).localeCompare(b.name));
        //     console.log('list', list);
        //     return list;
        // },
        // ACCOUNT_TYPE_ENTERPRISE() {
        //     return ACCOUNT_TYPE_ENTERPRISE;
        // },
        // ACCOUNT_TYPE_PERSONAL() {
        //     return ACCOUNT_TYPE_PERSONAL;
        // },
        // ACCOUNT_TYPE_FREE() {
        //     return ACCOUNT_TYPE_FREE;
        // },
    },

    watch: {
        // isGravatarEnabled(val, oldval) {
        //     console.log('isGravatarEnabled: %o (was: %o)', val, oldval);
        //     this.isGravatarEnabled = val;
        // },
        // Close when clicking outside of dialog
        isAuthenticatedReady(value, oldValue) {
            console.log('palette.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
        dialogDeletePalette(val) {
            if (!val) {
                this.closeDialogDeletePalette();
            }
        },
    },

    created() {
        console.log('created'); // : isAuthenticated: %o', this.isAuthenticated);
        // if (this.$route.query.acct) {
        //     const queryProductId = this.$route.query.acct;
        //     if (queryProductId !== this.currentProductId) {
        //         console.log(`palette.vue: setting current form to ${queryProductId}`);
        //         this.$store.commit('setProductId', queryProductId);
        //     }
        // }
        // if (!this.currentProduct.type) {
        //     this.isDeveloperExpansionPanelOpen = [0];
        // }
    },

    methods: {
        async loadAccount() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAccount: true });
                const response = await this.$client.account(this.$route.params.accountId).currentAccount.get();
                console.log(`loadAccount: response ${JSON.stringify(response)}`);
                if (response) {
                    this.account = response;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                }
            } catch (err) {
                console.error('failed to load account', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAccount: false });
            }
        },
        async loadPalette() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadPalette: true });
                const response = await this.$client.account(this.$route.params.accountId).palette.get({ id: this.$route.params.paletteId });
                console.log(`loadPalette: response ${JSON.stringify(response)}`);
                if (response) {
                    this.palette = response;
                    this.palette.label ??= '';
                    // this.palette.comment ??= '';
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load form');
                }
            } catch (err) {
                console.error('failed to load form', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadPalette: false });
            }
        },
        /*
        async loadAffectedEmailContactList() {
            try {
                this.error = false;
                this.$store.commit('loading', { loadAffectedEmailContactList: true });
                const response = await this.$client.account(this.$route.params.accountId).whateverOtherAsset.search({ palette_id: this.$route.params.paletteId });
                console.log(`loadAffectedEmailContactList: response ${JSON.stringify(response)}`);
                if (response) {
                    this.affectedEmailContactList = response.list;
                } else {
                    // TODO: redirect back to account list? show a not found message?
                    console.error('failed to load affected email contact list');
                }
            } catch (err) {
                console.error('failed to load affected email contact list', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { loadAffectedEmailContactList: false });
            }
        },
        */
        async deletePalette() {
            try {
                this.$store.commit('loading', { deletePalette: true });
                const response = await this.$client.account(this.$route.params.accountId).palette.delete({ id: this.$route.params.paletteId });
                if (response.isDeleted) {
                    this.$bus.$emit('snackbar', { type: 'info', headline: 'Deleted the palette' });
                    this.$router.push({ name: 'account-search-palette', params: { accountId: this.$route.params.accountId } });
                } else {
                    console.log('deletepalette.vue: deletePalette failed: %o', response);
                    this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete palette' });
                }
            } catch (err) {
                console.log('deletepalette.vue: deletePalette failed', err);
                this.$bus.$emit('snackbar', { type: 'error', headline: 'Failed to delete palette' });
            } finally {
                this.closeDialogDeletePalette();
                this.$store.commit('loading', { deletePalette: false });
            }
        },
        closeDialogDeletePalette() {
            this.dialogDeletePalette = false;
        },
    },
    mounted() {
        this.loadAccount();
        this.loadPalette();
        // this.loadAffectedEmailContactList();
    },
};
</script>
